<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Update Maintenance</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="id_cat_maintenance"
                    >Category Maintenance
                  </label>
                  <select
                    v-model="form.id_cat_maintenance"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_cat_maintenance = ''"
                    :class="{
                      'is-valid': form.id_cat_maintenance != '',
                      'is-invalid':
                        formValidate.id_cat_maintenance ||
                        form.id_cat_maintenance == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="kategori in listKategori"
                      :key="kategori.id_cat_maintenance"
                      :value="kategori.id_cat_maintenance"
                    >
                      {{ kategori.nama_cat_maintenance }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="item_maintenance"
                    >Item Maintenance
                  </label>
                  <input
                    v-model="form.item_maintenance"
                    type="email"
                    class="form-control"
                    placeholder="Input Item Maintenance"
                    required
                    @input="formValidate.item_maintenance = ''"
                    :class="{
                      'is-valid': form.item_maintenance != '',
                      'is-invalid':
                        formValidate.item_maintenance ||
                        form.item_maintenance == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="isChecked"
                    >Method Maintenance
                  </label>
                  <div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        value="true"
                        v-model="form.isChecked"
                        id="radioA"
                      />
                      <label for="radioA">Checklist</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        value="false"
                        v-model="form.isChecked"
                        id="radioE"
                      />
                      <label for="radioE">Value</label>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="standard_maintenance"
                    >Standard Maintenance
                  </label>
                  <input
                    v-model="form.standard_maintenance"
                    type="tel"
                    class="form-control"
                    placeholder="Input Standard Maintenance"
                    required
                    @input="formValidate.standard_maintenance = ''"
                    :class="{
                      'is-valid': form.standard_maintenance != '',
                      'is-invalid':
                        formValidate.standard_maintenance ||
                        form.standard_maintenance == '',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-md-6 mb-2">
                <a
                  href="javascript:window.history.go(-1);"
                  class="btn btn-default"
                  >Kembali</a
                >
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-success float-md-right"
                  @click="postData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";

export default {
  mixins: [userData],
  data() {
    return {
      form: {
        id_maintenance: "",
        id_cat_maintenance: "",
        item_maintenance: "",
        standard_maintenance: "",
        isChecked: "",
      },
      listKategori: [],
      formValidate: [],
      loading: false,
    };
  },
  mounted() {
    this.getCatergoryMaintenance();
    const id = this.$route.params.id;
    if (id) {
      this.fetchData(id);
    }
  },
  methods: {
    getCatergoryMaintenance() {
      axios
        .get(this.$url+"/v2/kategori_maintenance/")
        .then((response) => {
          this.listKategori = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData(id) {
      axios
        .get(this.$url+"/v2/maintenance/detail/" + id)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.$url+"/v2/maintenance/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>